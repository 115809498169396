import React, { useContext } from 'react';
import { Link } from 'gatsby';

import DistributionMap from '../components/distribution-map';
import { localizeUrl } from '../libs/locale';
import { LocaleContext } from '../components/locale-context';
import { getDistributionLabels } from '../queries/get-distribution-labels';

export default function DistributionLink () {
  const { locale } = useContext(LocaleContext);

  const distrData = getDistributionLabels(locale);

  return (
    <div className = "w3-center">
      <Link
        to = { localizeUrl(distrData.baseUrl, locale) }
        title = { distrData.tooltip }
        className = "w3-center w3-button w3-hover-none cpcolor-hover-border-brown w3-border"
      >
        <div className = "w3-center">
          <p>{ distrData.title }</p>
        </div>
        <div style = {{ maxWidth: '400px' }}>
          <DistributionMap />
        </div>
      </Link>
    </div>
  );
}
