import { useStaticQuery, graphql } from 'gatsby';
import { pageInfoByLocale } from '../queries/page-info-helpers';

export const getDistributionLabels = (locale: string) => {
  const { distr } = useStaticQuery(
    graphql`{
      distr: allPageIndexYaml (filter: {yamlId: {in: ["distribution"]}}) {
        nodes {
          ...PageInfo
        }
      }
    }`
  );

  const distMultiLocale = distr.nodes[0];
  return pageInfoByLocale(distMultiLocale)[locale];
}
