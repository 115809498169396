import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';

import RehypeReact from 'rehype-react';
import unified from 'unified';
import { Node } from 'unist';

import IntroSection from '../components/section-domain';
import DistributionLink from '../components/distribution-link';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';

import { PageInfo, pageInfoByLocale, updateSEO } from '../queries/page-info-helpers';
import { getLocalizedValue } from '../components/locale-context';
import HTMLDiv from '../components/htmldiv';
import { PageInfoData } from '../queries/fragment-page-info';

const renderAst = (ast: Node, d101html: string, prodhtml: string): JSX.Element => {
  // @ts-expect-error some weird RehypeReact typing issue
  const astProcessor = unified().use(RehypeReact, {
    createElement: React.createElement,
    components: {
      distribution: DistributionLink,
      domaine101: (props: Domaine101Props) => <Domaine101 {...props} htmlContent={d101html} />,
      domaineproduction: (props: DomaineProductionProps) => <DomaineProduction {...props} htmlContent={prodhtml} />
    }
  });
  return (astProcessor.stringify(ast) as unknown) as JSX.Element
}

function getSEO (location: LocationInfo, pageInfo: PageInfo) {
  const seo = PageSEO.fromLocation(location);
  updateSEO(seo, pageInfo);
  return seo;
}

interface Domaine101Props {
  htmlContent: string
}

function Domaine101 ({ htmlContent }: Domaine101Props): ReactElement {
  return <HTMLDiv __html = { htmlContent } />;
}

interface DomaineProductionProps {
  htmlContent: string
}

function DomaineProduction ({ htmlContent }: DomaineProductionProps): ReactElement {
  return <HTMLDiv __html = { htmlContent } />;
}

interface DomaineProps {
  data: PageDomaineData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function Domaine ({ data, pageContext, location }: DomaineProps): ReactElement {
  const { locale } = pageContext;
  const localPageInfo = getLocalizedValue(pageInfoByLocale(data.page), locale);
  const seo = getSEO(location, localPageInfo);
  const { md, d101, prod } = data;
  return (
    <Layout locale = { locale } location={location} title = { localPageInfo.title } seo = { seo }>
      <IntroSection />
      <div className = "w3-margin">
        { renderAst(md.htmlAst, d101.html, prod.html) }
      </div>
    </Layout>
  );
}

interface PageDomaineData {
  md: {
    htmlAst: Node 
  },
  d101: {
    html: string
  },
  prod: {
    html: string
  },
  page: PageInfoData
}

export const query = graphql`
query($locale: String!) {
  md: markdownRemark(fields: {slug: {regex: "/domaine\\.[a-z]{2}/"}, locale: {eq: $locale}}) {
    htmlAst
  }
  d101: markdownRemark(fields: {slug: {regex: "/domaine-101\\.[a-z]{2}/"}, locale: {eq: $locale}}) {
    html
  }
  prod: markdownRemark(fields: {slug: {regex: "/domaine-production\\.[a-z]{2}/"}, locale: {eq: $locale}}) {
    html
  }
  page: pageIndexYaml(yamlId : {eq: "domaine"}) {
    ...PageInfo
  }
}
`;
